import React from "react"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

export default ({data}) => (
  <Layout>
    <SEO title="About" />
        <h1>About</h1>
        <div class="pure-g">
            <div class="pure-u-1 pure-u-md-1-2">
                <div class="l-box"><Img fluid={data.analog.childImageSharp.fluid}/> Analog electronics. </div>
            </div>
            <div class="pure-u-1 pure-u-md-1-2">
                <div class="l-box"><Img fluid={data.electronics.childImageSharp.fluid}/> Embedded. </div></div>
            </div>
        <div class="pure-g">
            <div class="pure-u-1 pure-u-md-1-2">
                <div class="l-box"><Img fluid={data.ux.childImageSharp.fluid}/> UX Design. </div>
            </div>
            <div class="pure-u-1 pure-u-md-1-2">
                <div class="l-box"><Img fluid={data.distributed.childImageSharp.fluid}/> Distributed Systems.</div>
             </div>
        </div>
  </Layout>
)

export const query = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 800, maxHeight: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }

query {
    analog: file(relativePath: {eq: "analog.jpg"}) {
      ...squareImage
    }

    electronics: file(relativePath: {eq: "electronics.jpg"}) {
      ...squareImage
    }

    ux: file(relativePath: {eq: "ux.jpg"}) {
      ...squareImage
    }

    distributed: file(relativePath: {eq: "distributed.jpg"}) {
      ...squareImage
    }
}
`
